import { firestore as db } from "src/Firebase";

export const getReportes = (idConjunto, observer) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("issuesReport")
    .orderBy("Fecha", "desc")
    .onSnapshot(observer);

export const getAlertareporte = (idConjunto, observer) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("issuesReport")
    .where("Estado", "==", 0)
    .onSnapshot(observer);

export const updateReporte = (idConjunto, id, data) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("issuesReport")
    .doc(id)
    .update(data);
export const deleteReporte = (idConjunto, id) =>
  db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("issuesReport")
    .doc(id)
    .delete();
export const getAlertaUsuario = (idUsuario) =>
  db.collection("usuarios").doc(idUsuario).get();
