import { harmony, colorScheme } from "simpler-color";

function isValidHexColor(colorString) {
  const hexColorRegex = /^#?([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;
  return hexColorRegex.test(colorString);
}

const generatePalette = (color) => {
  if (!color || typeof color !== "string") {
    return {};
  }
  if (!isValidHexColor(color)) {
    throw Error("invalid hex color format");
  }
  const baseColors = harmony(color);
  const scheme = colorScheme(baseColors, (colors) => ({
    primaryContrastText: colors.primary(90),
    secondaryContrastText: colors.secondary(90),
  }));
  return {
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: baseColors.primary,
          },
        },
      },
      MuiButton: {
        startIcon: {
          color: baseColors.primary,
        },
        outlinedPrimary: {
          color: "#092147",
        },
      },

      MuiCardHeader: {
        root: {
          color: "white",
          backgroundColor: baseColors.primary,
        },
      },

      MuiDialogTitle: {
        root: {
          color: "white",
          backgroundColor: baseColors.primary,
        },
      },
      MuiAccordionSummary: {
        root: {
          color: "white",
          background: baseColors.primary,
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: baseColors.accent,
      },
      primary: {
        main: baseColors.primary,
        contrastText: scheme.primaryContrastText,
      },
      secondary: {
        main: baseColors.secondary,
        contrastText: scheme.secondaryContrastText,
      },
      text: {
        primary: "#092147",
        secondary: "#1F374D",
      },
    },
  };
};

export default generatePalette;
