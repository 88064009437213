/* eslint-disable default-param-last */
import axios from "src/utils/axios";

export const sendNotification = async (
  title = "",
  body = "",
  tokens = [],
  conjunto
) => {
  if (tokens.length < 1) {
    return;
  }
  const requests = tokens.map((token) => {
    const paramsPush = {
      id_conjunto: conjunto.id,
      titulo: title?.substring(0, 60),
      mensaje: body,
      topic: token,
    };
    return axios.post("qrcode/envia_push_masivo/", paramsPush);
  });
  await Promise.all(requests);
};
