import React from "react";
import { useSelector } from "react-redux";
import { selectIsKon } from "src/redux/slices/complexSlice";
import konLogo from "src/assets/img/kondominios/logo.png";
import logoCupro from "src/assets/img/logos/cuprovyg.png";
import logo593 from "src/assets/img/logos/593_logo.png";
import logoEps from "src/assets/img/logos/EPS_1.png";
import Img from "src/assets/img/mi.png";

const LogoDown = (props) => {
  const isKon = useSelector(selectIsKon);
  const domain = window.location.hostname;
  switch (domain) {
    case "app.cuprovyg.com":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logoCupro}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );
    case "app593security.micasitaapp.com":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logo593}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );

    case "eps.micasitaapp.com":
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={logoEps}
            style={{ width: 100, height: 90, margin: "auto" }}
            {...props}
          />
        </a>
      );

    case "qr.paladin.com.ec":
      return null;
    default:
      return (
        <a href="/" style={{ display: "flex" }}>
          <img
            alt="Logo"
            src={isKon ? konLogo : Img}
            style={{
              width: isKon ? 90 : 140,
              height: isKon ? 80 : 100,
              margin: "auto",
            }}
            {...props}
          />
        </a>
      );
  }
};

export default LogoDown;
