import React from 'react';
import {
  Backdrop, Fade, Grid, makeStyles, Modal, Typography,
} from '@material-ui/core';
import '../../layouts/DashboardAdmin/scrollbar.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    right: 0,
    margin: 'auto',
    overflow: 'auto',
  },
  root: {
    padding: theme.spacing(3),
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    border: 0,
    padding: 0,
    fontSize: 30,
    backgroundColor: 'transparent',
  },
}));

const CustomModal = ({
  isOpen, closeModal, children, title = '',
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={`${classes.paper} scroll`}>
          <div className={classes.root}>
            <Header title={title} onClick={closeModal} />
            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const Header = ({ title = '', onClick }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="space-between"
      style={{ marginBottom: 20 }}
    >
      <Grid item>
        <Typography component="h2" variant="h3">
          {title || 'Titulo'}
        </Typography>
      </Grid>
      <Grid item>
        <button
          onClick={onClick}
          aria-label="Close"
          className={classes.close}
          type="button"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Grid>
    </Grid>
  );
};

export default CustomModal;
