import { useEffect, useRef, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Badge from "@material-ui/core/Badge"
import PerfectScrollbar from "react-perfect-scrollbar"
import Constants from "src/components/Common/Constants"
import { Bell as BellIcon } from "react-feather"
import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"
import * as FirestoreService from "./services/firestore"

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}))

const Notifications = () => {
  const classes = useStyles()
  const ref = useRef(null)
  const conjunto = useSelector((state) => state.complex.complex)
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)
  const [visitasAnticipadas, setvisitasAnticipadas] = useState([])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    FirestoreService.getTodayEarlyVisitsWithAditionals(
      conjunto.id,
      conjunto.Relacionados
    )
      .then((query) => {
        const Items = query.map((doc) => {
          const data = doc.data()
          return {
            id: doc.id,
            ...data,
            PlacaVehiculo: data.PlacaVehiculo || "sin vehículo",
            ColorVehiculo: data.ColorVehiculo || "sin vehículo",
            HoraEstimadaLlegada:
              data.HoraEstimadaLlegada.toDate().toLocaleTimeString(),
            TiempoEstimadoSalida:
              Constants.tiemposEstimadosVisita?.find(
                (time) => time.value === data.TiempoEstimadoSalida
              )?.label || data.TiempoEstimadoSalida,
            TipoVisita:
              Constants.tiposVisita?.find(
                (time) => time.value === data.TipoVisita
              )?.label || data.TipoVisita,
          }
        })
        setvisitasAnticipadas(Items)
      })
      .catch(() => {
        enqueueSnackbar("Error al obtener las visitas anticipadas de hoy", {
          variant: "error",
        })
      })
  }, [conjunto])

  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton ref={ref} onClick={handleOpen}>
          <Badge
            badgeContent={
              visitasAnticipadas.length < 10 ? visitasAnticipadas.length : "+9"
            }
            color="primary"
            overlap="rectangular"
          >
            <BellIcon
              style={{ color: conjunto.Kondominios ? "white" : "initial" }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h4" color="textPrimary">
            Visitas Anticipadas
          </Typography>
        </Box>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <List
            disablePadding
            style={{ maxHeight: "400px", width: "100%", maxWidth: "400px" }}
          >
            {visitasAnticipadas.length > 0 ? (
              <List component="div" disablePadding>
                {visitasAnticipadas.slice(-5).map((item) => (
                  <ListItem
                    component={RouterLink}
                    divider
                    key={item.id}
                    to="/administrador/transferenciasporaprobar"
                  >
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="body2">
                            Lugar: {item.CasaDestino}
                          </Typography>
                          <Typography variant="body2">{"\n"}</Typography>
                          <Typography variant="body2">
                            Nombre: {item.Nombre} {item.Apellido}
                          </Typography>
                          <Typography variant="body2">{"\n"}</Typography>
                          <Typography variant="body2">
                            Residente: {item.NombreDestino}{" "}
                            {item.ApellidoDestino}
                          </Typography>
                          <Typography variant="body2">{"\n"}</Typography>
                          <Typography variant="body2">
                            Hora: {item.HoraEstimadaLlegada}
                          </Typography>
                        </>
                      }
                      primaryTypographyProps={{
                        variant: "subtitle2",
                        color: "textPrimary",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box p={2} display="flex" justifyContent="center">
                <Typography color="textPrimary">
                  No tiene Visitas anticipadas para hoy
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Button
                component={RouterLink}
                size="small"
                to="/guardia/visitasAnticipadas"
              >
                ir a visitas
              </Button>
            </Box>
          </List>
        </PerfectScrollbar>
      </Popover>
    </>
  )
}

export default Notifications
