import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    '*::-webkit-scrollbar': {
      width: '1em'
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: '#e4e4e4',
      borderRadius: '100px'
    },
    '*::-webkit-scrollbar-thumb': {
      border: '0.3em solid transparent',
      borderRadius: '100px',
      backgroundColor: '#082946',
      backgroundClip: 'content-box'
    },
    '.tg-container': {
      padding: theme.spacing(0, 2, 2, 2)
    },
    '.tg-table': {
      minWidth: 700
    },
    'space-normal': {
      whiteSpace: 'normal'
    }
  },
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
