import numeral from "numeral"

export const toDecimal = (value, decimal = 2) => {
  const num = Number(value)
  if (num) {
    return Number(num.toFixed(decimal))
  }
  return 0
}
export const formatCurrency = (value) => numeral(value).format('$0,0.00')
export const formatNumber = (value) => numeral(value).format('0,0.00')