import { Collapse, List, ListItem, ListItemText, MenuItem } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { changeRol } from 'src/redux/slices/authSlice'
import { resetTheme } from 'src/redux/slices/themeSlice'
import { Roles, basePaths } from 'src/utils/Constants'

const RolesSelector = ({ rol, roles }) => {
  const arrRoles = Object.keys(roles).filter(item => roles[item] && basePaths[item])
  const dispatch = useDispatch()
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const handleToggle = () => setOpen(prev => !prev)

  const handleSelector = (newrol) => {
    if (newrol === 'supervisor') {
      newrol = Roles.supervisor
    }
    dispatch(resetTheme())
    dispatch(changeRol(newrol))
    history.replace(basePaths[newrol])
    handleToggle()
  }

  if (arrRoles.length <= 1) return null

  return (
    <>
      <MenuItem divider onClick={handleToggle}>
        Rol: {rol}
        {open ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component='div' disablePadding>
          {arrRoles.map(item => (
            <ListItem key={item} divider button onClick={() => handleSelector(item)}>
              <ListItemText primary={item} primaryTypographyProps={{ variant: 'subtitle2' }} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default RolesSelector