import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
    width: "100%",
    minHeight: "calc(100vh - 80px)",
  },
  chartContainer: {
    width: "100%",
  },
}))
export default useStyles
