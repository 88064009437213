import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "src/components/Common/LoadingScreen";
import DashboardParkey from "src/layouts/DashboardParkey";


const Parking = lazy(() => import("./parking"));
const BitacorasParking = lazy(() => import("./parking/Bitacora/index"));
const PagosParking = lazy(() => import("./parking/Pagos/index"));
const NotFoundView = lazy(() => import("../errors/NotFoundView"));

const Guardia = ({ match }) => (
  <DashboardParkey>
    <Suspense fallback={<LoadingScreen />}>
   
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/parqueadero`}
        />
        <Route
          path={`${match.url}/parqueadero`}
          render={(props) => <Parking {...props} />}
        />
        <Route
          path={`${match.url}/bitacoraParkey`}
          render={(props) => <BitacorasParking {...props} />}
        />
        <Route
          path={`${match.url}/pagosParkey`}
          render={(props) => <PagosParking {...props} />}
        />
        <Route render={(props) => <NotFoundView {...props} />} />
      </Switch>
    </Suspense>
  </DashboardParkey>
);

export default Guardia;
