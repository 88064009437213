import { setLocale, addMethod, mixed } from "yup";
import { esConfig } from "./yup.configEs";

const twoDecimalsReg = /^(-)?([0-9]+(\.|,)?[0-9]{0,2})$/;

setLocale(esConfig)

// eslint-disable-next-line func-names
addMethod(mixed, 'maxDecimals', function (message) {
  return this.test(
    'max-decimals',
    message ?? 'Solo se aceptan valores con 2 decimales',
    (value) => twoDecimalsReg.test(value)
  )
})