import firebase from "src/Firebase"

const storage = firebase.storage()

export const deleteFileByUrl = async (link) => {
  try {
    const refFromUrl = decodeURIComponent(link.split("/").pop().split("?")[0])
    await storage.ref(refFromUrl).delete()
  } catch (error) {
    return undefined
  }
}
