/* eslint-disable no-console */
import {
  collection,
  doc as dbDoc,
  onSnapshot,
  query as dbQuery,
  where,
} from "firebase/firestore";
import chunk from "lodash/chunk";
import moment from "moment";
import app, { dbv9 } from "src/Firebase";
import { store } from "src/redux/stores/store";
import { defaultFormaCobroPago, defaultTipoVisitas } from "src/utils/Constants";
import { deleteFileByUrl } from "src/utils/deleteFileByUrl";
import {
  FormadeCobroPago,
  TipoVisita,
  createRubros,
} from "src/views/conjuntos/services/firestore";

const db = app.firestore();

export const complexRefv9 = (id) => {
  if (id) {
    return dbDoc(dbv9, "conjuntos", id);
  }
  const { complex } = store.getState().complex;
  return dbDoc(dbv9, "conjuntos", complex.id);
};

export const complexRef = (id) => {
  if (id) {
    return db.collection("conjuntos").doc(id);
  }
  const { complex } = store.getState().complex;
  return db.collection("conjuntos").doc(complex.id);
};

/**
 * get all complex, pass filter array to filter results
 * @param {[fieldPath: string, opStr: string, value: string]} filter
 * @returns Promise to resolve in Array of Complex data
 */
export const getAllComplex = async (filter) => {
  try {
    let query = db.collection("conjuntos");
    if (filter) {
      query = query.where(filter[0], filter[1], filter[2]);
    }
    const complexes = await query.get();
    return complexes.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw Error("error getting complex");
  }
};

/**
 * Attaches a listener for DocumentSnapshot events, filter by one field
 * @param {{next: function, error: function}} observer
 * @param {[fieldPath: string, opStr: string, value: string]} filter
 * @returns unsubscribe function to stop observer
 */
export const suscriberAllComplex = (observer, filter) => {
  try {
    let q = collection(dbv9, "conjuntos");
    if (filter) {
      q = dbQuery(q, where(filter[0], filter[1], filter[2]));
    }
    return onSnapshot(q, observer);
  } catch (error) {
    observer.error(error);
  }
};

export const createComplex = async (data, Modulo) => {
  const doc = await db.collection("conjuntos").add(data);
  await createRubros(doc.id);
  await FormadeCobroPago(doc.id, { newFormaCobroPago: defaultFormaCobroPago });
  await TipoVisita(doc.id, defaultTipoVisitas);
  if (Modulo) {
    await complexRef(doc.id)
      .collection("conjuntoConfig")
      .doc("Modulos")
      .update(Modulo);
  }
};

export const getComplexModules = (id) =>
  complexRef(id).collection("conjuntoConfig").doc("Modulos").get();

export const updateConjuntoModulos = (data, id) =>
  complexRef(id).collection("conjuntoConfig").doc("Modulos").update(data);

export const updateComplexe = (data, id) =>
  db.collection("conjuntos").doc(id).update(data);
export const updateComplexeAviCob = (data, id) =>
  db.collection("conjuntos").doc(id).update({
    NumeroAvisoCobro: data,
    FechaAvisoCobro: new Date(),
  });
export const updateUsersAviCob = (data, id) =>
  db.collection("usuarios").doc(id).update({
    NumeroAvisoCobro: data,
    FechaAvisoCobro: new Date(),
  });

export const updateUser = (data, id) =>
  db.collection("usuarios").doc(id).update(data);

export const getComplexUnits = (id) =>
  complexRef(id).collection("unidadesHabitacionales").get();
export const getComplexUsers = (id) =>
  db.collection("usuarios").where("ConjuntoUidResidencia", "==", id).get();

export const getUsersTGLabs = () =>
  db.collection("usuarios").where("TGLabs", "==", true).get();

/* Rubros del conjunto */
export const getAllRubros = () => complexRef().collection("rubros").get();

/* Ingresos del conjunto */
export const getIngresos = async (desde, hasta) => {
  const baseRef = complexRef().collection("ingresos");
  if (desde && hasta) {
    const query = await baseRef
      .orderBy("Fecha", "asc")
      .startAt(desde)
      .endAt(hasta)
      .get();
    return query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  }
  const query = await baseRef.get();
  return query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};
/* Egresos del conjunto */
export const getEgresos = async (desde, hasta) => {
  const baseRef = complexRef().collection("egresos");
  if (desde && hasta) {
    const query = await baseRef
      .orderBy("Fecha", "asc")
      .startAt(desde)
      .endAt(hasta)
      .get();
    return query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  }
  const query = await baseRef.get();
  return query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

/* campos de conjunto */
export const suscriberVoucherNumber = (observer) =>
  complexRef().onSnapshot(observer);

// eslint-disable-next-line no-unused-vars
const updateGuards = async () => {
  const users = await db.collection("usuarios").get();
  const formatUsers = users.docs.map((doc) => doc.id);
  const splitUsers = chunk(formatUsers, 500);
  const promises = splitUsers.map((innerUsers) => {
    const batch = db.batch();
    innerUsers.forEach((user) => {
      const userRef = db.collection("usuarios").doc(user);
      batch.update(userRef, { isActive: true });
    });
    return batch.commit();
  });
  try {
    await Promise.all(promises);
  } catch (error) {
    throw Error(error);
  }
};

export const updateIncomesToReconcile = async (idConjunto, fecha) => {
  try {
    if (!idConjunto) {
      throw Error("No hay un id de conjunto para actualizar");
    }
    const incomeRef = db.collection(`conjuntos/${idConjunto}/ingresos`);
    const expenseRef = db.collection(`conjuntos/${idConjunto}/egresos`);
    const finalDate = moment(fecha).endOf("day").toDate();
    console.log("buscando las trasacciones hasta: ", finalDate);
    const [incomes, expenses] = await Promise.all([
      incomeRef.where("Fecha", "<=", finalDate).get(),
      expenseRef.where("Fecha", "<=", finalDate).get(),
    ]);
    console.log("split de las trasacciones");
    const splitedIng = chunk(incomes.docs, 500);
    const splitedEgr = chunk(expenses.docs, 500);
    console.log("armando las promesas de ingresos");
    const promisesIng = splitedIng.map((innerIng) => {
      const batch = db.batch();
      innerIng.forEach((doc) => {
        batch.update(doc.ref, { Conciliado: true });
      });
      return batch.commit();
    });
    console.log("armando las promesas de egresos");
    const promisesEgr = splitedEgr.map((innerEgr) => {
      const batch = db.batch();
      innerEgr.forEach((doc) => {
        batch.update(doc.ref, { Conciliado: true });
      });
      return batch.commit();
    });
    console.log("actualizando los ingresos");
    await Promise.all(promisesIng);
    console.log("actualizando los egresos");
    await Promise.all(promisesEgr);
    console.log("fin :)");
  } catch (error) {
    console.error(error);
  }
};

/**
 * function to handle delete complex by complex data
 * @param {{ id: string, Imagen: string }} data conjunto para eliminar
 */
export const deleteComplex = async (data) => {
  await db.collection("conjuntos").doc(data.id).delete();
  await deleteFileByUrl(data.Imagen);
};

export const getComplexEmpresa = (id) =>
  db.collection("empresas").doc(id).get();