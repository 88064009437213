import app from "src/Firebase"
import moment from "moment"

const db = app.firestore()

export const getTodayEarlyVisitsWithAditionals = async (
  conjuntoID,
  aditionals = []
) => {
  const visitasColl = (id) =>
    db
      .collection("conjuntos")
      .doc(id)
      .collection("visitasAnticipadas")
      .where("HoraEstimadaLlegada", ">=", moment().startOf("day").toDate())
      .where("HoraEstimadaLlegada", "<=", moment().endOf("day").toDate())
      .where("EstadoSalida", "==", false)

  const relacionados = aditionals.filter((id) => id !== conjuntoID)
  relacionados.push(conjuntoID)
  const results = await Promise.all(
    relacionados.map((id) => visitasColl(id).get())
  )
  const data = []
  results.forEach((res) => {
    data.push(...res.docs)
  })
  return data
}
