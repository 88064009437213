import Page from "src/components/Page"
import { useSelector } from "react-redux"
import moment from "moment"
import { useSnackbar } from "notistack"
import React, { useState, useEffect } from "react"
import axios from "src/utils/axios"
import SearchIcon from "@material-ui/icons/Search"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Grid, Typography, Button, Tooltip } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import DateFnsUtils from "@date-io/date-fns"
import esLocale from "date-fns/locale/es"
import { Bar } from "react-chartjs-2"
import useStyles from "./useStyles"

const ReporteDiarioVisitas = () => {
  const columns = [
    { id: "00", label: "00h" },
    { id: "01", label: "01h" },
    { id: "02", label: "02h" },
    { id: "03", label: "03h" },
    { id: "04", label: "04h" },
    { id: "05", label: "05h" },
    { id: "06", label: "06h" },
    { id: "07", label: "07h" },
    { id: "08", label: "08h" },
    { id: "09", label: "09h" },
    { id: "10", label: "10h" },
    { id: "11", label: "11h" },
    { id: "12", label: "12h" },
    { id: "13", label: "13h" },
    { id: "14", label: "14h" },
    { id: "15", label: "15h" },
    { id: "16", label: "16h" },
    { id: "17", label: "17h" },
    { id: "18", label: "18h" },
    { id: "19", label: "19h" },
    { id: "20", label: "20h" },
    { id: "21", label: "21h" },
    { id: "22", label: "22h" },
    { id: "23", label: "23h" },
  ]
  const classes = useStyles()
  const conjunto = useSelector((state) => state.complex.complex)
  const [startdesde, setStartDesde] = useState(moment().startOf("year"))
  const [starthasta, setStartHasta] = useState(moment())
  const [loading, setLoading] = useState(false)
  const [showBar, setShowBar] = useState(false)
  const [visitas, setVisitas] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ])
  const { enqueueSnackbar } = useSnackbar()

  async function getBitacora() {
    setLoading(true)
    setVisitas([
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ])
    const desde = moment(startdesde).format("YYYY-MM-DD")
    const hasta = moment(starthasta).format("YYYY-MM-DD")

    const diff = moment(hasta).diff(moment(desde), "days")

    if (diff < 32) {
      const url = `data_bitacora/`
      axios
        .post(url, {
          desde: moment(startdesde).format("YYYY-MM-DD"),
          hasta: moment(starthasta).format("YYYY-MM-DD"),
          id_conjunto: conjunto.id,
        })
        .then(async (res) => {
          res.data.data.forEach((visit) => {
            const dateTime = new Date(visit.hora_entrada)
            const hour = dateTime.getHours()
            setVisitas((prevVisitas) => {
              const newVisitas = [...prevVisitas]
              newVisitas[hour]++
              return newVisitas
            })
          })
          setVisitas((prevVisitas) => {
            const newVisitas = [...prevVisitas]
            return newVisitas
          })

          setShowBar(true)

          setLoading(false)
        })
        .catch(() => {
          enqueueSnackbar(
            "Ha ocurrido un error de conectividad, porfavor reinicia y vuelve a intentarlo",
            { variant: "error" }
          )
          setLoading(false)
          setShowBar(false)
        })
    } else {
      enqueueSnackbar("Seleccion un rango de fecha de MAXIMO 1 MES", {
        variant: "error",
      })
      setShowBar(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    setShowBar(false)
  }, [conjunto.id])

  return (
    <Page title="Actividad por Hora" className={classes.root}>
      <Typography variant="h3" align="center">
        REPORTE DE VISITAS - ACTIVIDAD POR HORA
      </Typography>

      <div className={classes.chartContainer}>
        <Grid
          className="mt-3 mb-2 justify-content-center"
          container
          spacing={3}
        >
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                label="Desde"
                inputVariant="outlined"
                variant="inline"
                format="dd-MM-yyyy"
                size="small"
                fullWidth
                autoOk
                value={startdesde}
                selected={startdesde}
                maxDate={starthasta}
                onChange={(date) => setStartDesde(moment(date))}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                label="Hasta"
                inputVariant="outlined"
                variant="inline"
                size="small"
                format="dd-MM-yyyy"
                fullWidth
                autoOk
                locale="es"
                value={starthasta}
                selected={starthasta}
                minDate={startdesde}
                maxDate={new Date()}
                minDateMessage="La fecha de inicio no puede ser mayor a la fecha de finalización."
                onChange={(date) => setStartHasta(moment(date))}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title="Buscar">
              <Button
                variant="contained"
                color="secondary"
                className="me-2 align-self-center"
                size="large"
                disabled={loading}
                onClick={() => getBitacora()}
              >
                <SearchIcon>Buscar</SearchIcon>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {showBar ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                El gráfico representa la cantidad de personas que han ingresado
                al conjunto en un hora determinada basado en el rango de fechas
                establecido, en el eje horizontal (x) se representan las 24
                horas del día mientras que, en el eje vertical (y) se
                representan la cantidad de personas que ingresaron.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Bar
                data={{
                  labels: columns.map((col) => col.label),
                  datasets: [
                    {
                      type: "bar",
                      label: "Gráfico de Reporte de Actividad por Hora",
                      borderColor: "rgb(75, 192, 192)",
                      backgroundColor: "rgb(75, 192, 192)",
                      borderWidth: 2,
                      data: visitas,
                    },
                  ],
                }}
                width="50%"
                height="15%"
                className="mb-3"
              />
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Typography variant="h6" align="center">
              Seleccione un rango de fechas para desplegar el grafico
            </Typography>
          </Grid>
        )}
      </div>
    </Page>
  )
}
export default ReporteDiarioVisitas
