import React from 'react';
import { makeStyles } from '@material-ui/core';
import {Internet} from "src/utils/Internet"

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 120,
    height: 50,
    objectFit: 'contain',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
}));

const Logo = ({ img, ...rest }) => {
  const classes = useStyles();
  return (
    <>
    <img
      alt="Logo"
      className={classes.logo}
      src={img}
      {...rest}
    />
     <Internet />
    </>
  );
};

export default Logo;
