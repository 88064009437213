export default {
  // asyncStorage
  STORE_ID_USER: 'id_user',
  USER_TOKEN: 'token',
  tiemposEstimadosVisita: [
    { id: 1, value: 0.08, label: '5 minutos' },
    { id: 2, value: 0.25, label: '15 minutos' },
    { id: 3, value: 0.5, label: '30 minutos' },
    { id: 4, value: 1, label: '1 hora' },
    { id: 5, value: 2, label: '2 horas' },
    { id: 6, value: 'Indefinido', label: 'Sin Tiempo' },
  ],
  tiposVisita: [
    { id: 1, value: 'Visita', label: 'Visita' },
    { id: 2, value: 'Empleado', label: 'Empleado' },
    { id: 3, value: 'Servicio de entregas', label: 'Servicio de entregas' },
    { id: 4, value: 'Servicio de taxis', label: 'Servicio de taxi' },
    { id: 5, value: 'Residente', label: 'Residente' },
    { id: 6, value: 'Servicio de emergencia', label: 'Servicio de emergencia' },
    { id: 7, value: 'Proveedor', label: 'Proveedor' },
    { id: 8, value: 'Paciente', label: 'Paciente' },
    { id: 9, value: 'Otro', label: 'Otro' },
  ],
  weekDays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  modules: {
    binnacle: {
      active: true,
      features: {
        autocomplete: {
          addressee: false
        }
      },
      permissions: {}
    }
  }
};
