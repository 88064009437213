import firebase from 'src/Firebase'

const db = firebase.firestore()

const authMigration = async (state) => {
  if (!state?.isAuth) {
    return state
  }
  const { docs } = await db.collection('usuarios').where('Correo', '==', state.user.email).limit(1).get()
  const user = docs[0]
  if (!user) {
    return state
  }
  if (user.data().Empresa) {
    state.user.Empresa = user.data().Empresa
  }
  const rehydrateState = {
    ...state,
    user: {
      ...state.user,
      name: user.data().Nombre,
      lastName: user.data().Apellido,
      email: user.data().Correo,
      phone: user.data().Telefono,
      identity: user.data().Cedula,
      roles: user.data().Roles,
      ConjuntoUidResidencia: user.data().ConjuntoUidResidencia,
    }
  }
  return rehydrateState
}
export default authMigration