import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Logo from "src/components/Common/Logo";
import Account from "src/components/Topbar/Account";
import { selectIsKon } from "src/redux/slices/complexSlice";
// import CamarasConjunto from "./CamarasConjunto";
import ConjuntosSelect from "./ConjuntosSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    background: ({ isKon }) =>
      isKon
        ? "linear-gradient(70deg, rgba(144,232,234,1) 12%, rgba(0,201,213,1) 42%, rgba(15,93,167,1) 74%, rgba(6,60,153,1) 93%)"
        : "white",
    zIndex: "1029",
    [theme.breakpoints.up("xs")]: {
      paddingLeft: 70,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar = ({ className, onMobileNavOpen, img, ...rest }) => {
  const selectGarita = useSelector((state) => state.complex.selectGarita);
  const imagen = useSelector((state) => state.complex.complex?.Imagen);
  const isKon = useSelector(selectIsKon);
  const classes = useStyles({ isKon });

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Logo img={imagen} />
        <Box ml={2} flexGrow={1} />

        {selectGarita && (
          <Box marginX={3}>
            <Typography color="primary">
              Garita Actual: {selectGarita}
            </Typography>
          </Box>
        )}
        <ConjuntosSelect />
        {
          // <CamarasConjunto />
        }
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
