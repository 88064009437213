import { getComplexModules } from 'src/services/complexServices'
import Constants from 'src/components/Common/Constants';

const complexMigration = async (state) => {
  if (!state?.complex?.id) {
    return state
  }
  const remoteModules = await getComplexModules(state.complex.id)
  if (!remoteModules?.exists) {
    return state
  }
  const rehydrateState = {
    ...state,
    modules: { ...Constants.modules, ...remoteModules.data() }
  }
  return rehydrateState
}

export default complexMigration
