import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  complexRef, getComplexModules, getComplexUsers, getComplexUnits, updateComplexe
} from 'src/services/complexServices';
import Constants from 'src/components/Common/Constants';
import { fetchTheme } from './themeSlice';

const initialState = {
  complex: {},
  isLoading: false,
  error: '',
  selectComplex: 0,
  selectGarita: null,
  users: [],
  units: [],
  modules: Constants.modules
};

export const getComplex = createAsyncThunk('complex/get', async (complexId, { dispatch }) => {
  const doc = await complexRef(complexId).get()
  const data = { id: doc.id, ...doc.data() };
  dispatch(fetchTheme(data.Empresa))
  return data;
});

export const updateComplex = createAsyncThunk('complex/update', async ({ data, id, allData }) => {
  await updateComplexe(data, id)
  const newdata = { ...allData, id }
  return newdata
})

export const getRemoteModules = createAsyncThunk('complex/modules', async (complexId) => {
  const remoteModules = await getComplexModules(complexId)
  if (remoteModules?.exists) {
    return { ...initialState.modules, ...remoteModules.data() }
  }
  return initialState.modules
})

export const getRemoteUsers = createAsyncThunk('complex/users', async (complexId) => {
  const remoteUsers = await getComplexUsers(complexId)

  const UsersID = remoteUsers.docs
    .map((snap) => ({ ...snap.data(), id: snap.id }))
    .sort((a, b) => a.Nombre.localeCompare(b.Nombre, 'en', { numeric: true }));

  return UsersID
})

export const getRemoteUnits = createAsyncThunk('complex/units', async (complexId) => {
  const remoteUnits = await getComplexUnits(complexId)
  const UnitsID = remoteUnits.docs
    .map((snap) => ({ ...snap.data(), id: snap.id }))
    .sort((a, b) => a.Numero.localeCompare(b.Numero, 'en', { numeric: true }));;
  return UnitsID
})

export const complexSlice = createSlice({
  name: 'complex',
  initialState,
  reducers: {
    removeComplex: (state) => {
      state.complex = {};
      state.selectComplex = 0
      state.selectGarita = null
      state.users = []
      state.units = []
    },
    selectComplex: (state, { payload }) => {
      state.selectComplex = payload
    },
    selectGarita: (state, { payload }) => {
      state.selectGarita = payload
    }
  },
  extraReducers: {
    [getComplex.pending]: (state) => {
      state.isLoading = true;
    },
    [getComplex.fulfilled]: (state, { payload }) => {
      state.complex = payload;
      state.isLoading = false;
    },
    [getComplex.rejected]: (state) => {
      state.isLoading = false;
    },
    [getRemoteModules.fulfilled]: (state, { payload }) => {
      state.modules = payload
    },
    [getRemoteUsers.fulfilled]: (state, { payload }) => {
      state.users = payload
    },
    [getRemoteUnits.fulfilled]: (state, { payload }) => {
      state.units = payload
    },
    [updateComplex.fulfilled]: (state, { payload }) => {
      state.complex = { ...state.complex, ...payload };
    }
  },
});

export const selectCamaraGarita = (state) => state.complex.selectGarita
export const selectBinnacleModule = (state) => state.complex.modules.binnacle
export const selectModules = (state) => state.complex.modules
export const selectConjunto = (state) => state.complex.complex
export const selectIsKon = (state) => state.complex.complex.Kondominios
export const selectIsParkey = (state) => state.complex.complex.Parkey
export const selectUsers = (state) => state.complex.users
export const selectUnits = (state) => state.complex.units

export const {
  removeComplex, selectComplex, selectGarita,
  users, units
} = complexSlice.actions;

export default complexSlice.reducer;
