import firebase from 'src/Firebase';
import moment from 'moment';

const db = firebase.firestore();

const maintenanceRef = (idcollection) => db.collection('conjuntos').doc(idcollection).collection('mantenimientos')

export const getMantenimientos = (idconjunto, observer) => maintenanceRef(idconjunto)
  .where('rrule.until', '>=', moment().subtract(6, 'months').toDate()).onSnapshot(observer)

export const addMantenimiento = (idconjunto, data) => maintenanceRef(idconjunto).add(data)

export const updateMantenimiento = (idconjunto, idmant, data) => maintenanceRef(idconjunto)
  .doc(idmant).update(data)

export const deleteMantenimiento = (idconjunto, idmant) => maintenanceRef(idconjunto)
  .doc(idmant).delete()

// legacy code mantenimientos
export const suscriberPendingMaintenances = (observer, idConjunto) => maintenanceRef(idConjunto)
  .where('ManFuturo', '<=', moment().format('yyyy-MM-DD'))
  .orderBy('ManFuturo', 'asc')
  .onSnapshot(observer);
