export const PeriodosAreas = {
  iterator: [
    { label: 'Semanal', value: 7 },
    { label: 'Mensual', value: 30 },
    { label: 'Anual', value: 365 },
  ],
  values: {
    '7': 'Semanal',
    '30': 'Mensual',
    '365': 'Anual',
  }
}

export const TipoReporte = {
  values: {
    '1': 'Quejas',
    '2': 'Reclamos',
    '3': 'Peticiones',
    '4': 'Sugerencias',
    '5': 'Felicitaciones',
    '6': 'Otros'
  }
}

export const Estado = {
  values: {
    '0': 'Pendiente',
    '1': 'En progreso',
    '2': 'Completado',
    '3': 'Calificado',
  }
}

export const Calificacion = {
  values: {
    '0': 'Pendiente de calificar',
    '1': 'Nivel de satisfacción malo',
    '2': 'Nivel de satisfacción regular',
    '3': 'Nivel de satisfacción medio',
    '4': 'Nivel de satisfacción bueno',
    '5': 'Nivel de satisfacción excelente',
  }
}

export const Roles = {
  superadmin: 'superAdministrador',
  admin: 'administrador',
  supervisor: 'supervisor',
  company: 'empresa',
  parkey: 'parkey',
  directiva: 'directiva',
  // resident: 'residente',
}

export const basePaths = {
  [Roles.superadmin]: '/superAdmin',
  [Roles.admin]: '/administrador',
  [Roles.supervisor]: '/guardia',
  [Roles.company]: '/empresa',
  [Roles.parkey]: '/parkey',
  [Roles.directiva]: '/directiva',
}

export const defaultRubros = []

export const defaultFormaCobroPago = [
  {
    name: "Efectivo",
    showCaja: true,
    showBancos: false,
  },
  {
    name: "Cheque",
    showCaja: true,
    showBancos: false,
  },
  {
    name: "Transferencia",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Deposito",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Tarjeta credito/debito",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Nota de debito",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Debito bancario",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Debito",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Intereses",
    showCaja: false,
    showBancos: true,
  },
  {
    name: "Cheque en banco",
    showCaja: false,
    showBancos: true,
  },
];

export const defaultTipoVisitas = [
  {
    Nombre: "Visita",
    Valor: 0,
    Tiempo: "Sin Tiempo",
  },
  {
    Tiempo: "1 hora",
    Nombre: "Empleado",
    Valor: 1,
  },
  {
    Valor: 2,
    Tiempo: "2 horas",
    Nombre: "Servicio de entregas",
  },
  {
    Nombre: "Servicio de taxi",
    Tiempo: "15 minutos",
    Valor: 0.25,
  },
  {
    Nombre: "Residente",
    Tiempo: "Indefinido",
    Valor: 0,
  },
  {
    Tiempo: "2 horas",
    Nombre: "Servicio de emergencia",
    Valor: 2,
  },
  {
    Valor: 1,
    Nombre: "Proveedor",
    Tiempo: "1 hora",
  },
  {
    Valor: 0,
    Tiempo: "Sin Tiempo",
    Nombre: "Paciente",
  },
  {
    Nombre: "Otro",
    Valor: 0,
    Tiempo: "Sin Tiempo",
  },
]