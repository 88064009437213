import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore'; // <----

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
const app = firebase.initializeApp(firebaseConfig);
const appv9 = initializeApp(firebaseConfig)
// apps on Firebase v8 SDK
export const auth = app.auth();
export const firestore = app.firestore()
// apps on Firebase v9 SDK
export const dbv9 = getFirestore(appv9)
export const authv9 = getAuth(appv9)
export const storagev9 = getStorage(appv9)

export default app;
