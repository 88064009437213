import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { THEMES } from 'src/constants'
import generatePalette from 'src/theme/generatePalette'
import { getEmpresa } from 'src/views/viewSuperAdministrador/Company/services/firestore'

const initialState = {
  name: THEMES.LIGHT,
  custom: {},
}

const fetchTheme = createAsyncThunk('theme/fetchTheme', async (id) => {
  const res = await getEmpresa(id)
  return generatePalette(res.Color)
})

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    resetTheme: (state) => {
      state.name = initialState.name
      state.custom = initialState.custom
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTheme.fulfilled, (state, action) => {
        state.custom = action.payload
        state.name = 'custom_theme'
      })
      .addCase(fetchTheme.rejected, (state) => {
        state.name = THEMES.LIGHT
        state.custom = {}
      })
  }
})

// export async thunks
export { fetchTheme }
// export slice getters
export const getCustomTheme = (state) => state.theme
// export slice actions
export const { resetTheme } = themeSlice.actions
// export slice reducer
export default themeSlice.reducer