import React from 'react';
import { AppBar, Box, Toolbar } from '@material-ui/core';
import TgLogo from 'src/assets/img/LOGOYTEXTO.svg';
import Logo from 'src/components/Common/Logo';
import Account from 'src/components/Topbar/Account';
import Notifications from './Notifications';
import Search from './Search';

const TopBar = ({ img = TgLogo }) => (
  <AppBar color="inherit">
    <Toolbar>
      <Box flexGrow={1} />
      <Logo img={img} />
      <Box flexGrow={1} />
      <Search />
      <Notifications />
      <Box ml={2}>
        <Account />
      </Box>
    </Toolbar>
  </AppBar>
);

export default TopBar;
