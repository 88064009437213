import { firestore as db } from "src/Firebase";

export const getEmpresa = async (id) => {
  if (id && id !== "") {
    const doc = await db.collection("empresas").doc(id).get();
    return { ...doc.data(), id: doc.id };
  }
  return { Color: "#092147" };
};

export const newEmpresa = (data) => db.collection("empresas").add(data);

export const updateEmpresa = (id, values) =>
  db.collection("empresas").doc(id).update(values);

export const getEmpresas = (observer) =>
  db.collection("empresas").onSnapshot(observer);

export const deleteEmpresaId = (id) =>
  db.collection("empresas").doc(id).delete();

export const getConjuntos = (Empresa) =>
  db.collection("conjuntos").where("Empresa", "==", Empresa).get();

export const getUnidades = (Empresa) =>
  db
    .collection("conjuntos")
    .doc(Empresa)
    .collection("unidadesHabitacionales")
    .get();

export const getUsers = (conjuntoID) =>
  db
    .collection("usuarios")
    .where("ConjuntoUidResidencia", "==", conjuntoID)
    .get();
export const getAllAdmins = (observer) =>
  db
    .collection("usuarios")
    .where("Roles.administrador", "==", true)
    .onSnapshot(observer);

export const getAllConjuntos = (observer) =>
  db.collection("conjuntos").onSnapshot(observer);
