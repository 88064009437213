import axios from 'axios';
import { auth } from 'src/Firebase';
import { store } from 'src/redux/stores/store';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API,
});

axiosInstance.interceptors.request.use(
  async config => {
    const url = store.getState().complex.modules.UseHeroku
      ? process.env.REACT_APP_HEROKU_API
      : process.env.REACT_APP_BASE_URL_API

    config.baseURL = url

    const token = await auth.currentUser.getIdToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers.Version = "v3"
    }
    return config
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,

  (error) => Promise.reject(error),

);

export default axiosInstance;
 
