/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";

export const Internet = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    !isOnline && (
      <div
        className="alert alert-danger"
        role="alert"
        style={{
          marginTop: "10px",
          display: "inline-block",
          padding: "7px 10px",
        }}
      >
        <h5> Este dispositivo no tiene conexión ha Internet :( </h5>
      </div>
    )
  );
};
