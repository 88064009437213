import React, { useRef, useState } from "react"
import { useSnackbar } from "notistack"
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectRol, selectUser, signOut } from "src/redux/slices/authSlice"
import { removeComplex, selectIsKon } from "src/redux/slices/complexSlice"
import { resetTheme } from "src/redux/slices/themeSlice"
import { Roles } from "src/utils/Constants"
import RolesSelector from "./RolesSelector"

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}))

const Account = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const rol = useSelector(selectRol)
  const user = useSelector(selectUser)
  const isKon = useSelector(selectIsKon)
  const { enqueueSnackbar } = useSnackbar()

  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      await dispatch(signOut()).unwrap()
      dispatch(removeComplex())
      dispatch(resetTheme())
    } catch (err) {
      enqueueSnackbar("Error al cerrar su sesion", {
        variant: "error",
      })
    }
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={user.avatar} />
        <Hidden xsDown>
          <Typography variant="h6" style={{ color: isKon ? 'white' : 'initial' }}>
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        {rol === Roles.admin && (
          <>
            <MenuItem
              to="/administrador/conjuntos/configuraciones"
              component={Link}
            >
              Configuraciones
            </MenuItem>
            <MenuItem
              to="/administrador/editarConjunto"
              component={Link}
            >
              Editar Conjunto
            </MenuItem>
          </>
        )}
        <RolesSelector rol={rol} roles={user.roles} />
        <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
      </Menu>
    </>
  )
}

export default Account
