import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { selectRol, selectUser } from 'src/redux/slices/authSlice';

const ProtectedRoute = ({ component: Component, roles = [], ...rest }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const user = useSelector(selectUser);
  const rol = useSelector(selectRol);

  const setComponent = (props) => {
    // if user id not authenticated or not loaded user data redirect to root route
    if (!isAuth || !user) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
    // if there is no roles or current rol is abled to access to this route render route comp
    if (roles.length === 0 || (roles.includes(rol) && user.roles[rol])) {
      return <Component {...props} />;
    }
    // if the current rol is not abled to access then redirect to unauth view
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: { from: props.location },
        }}
      />
    );
  };

  return (
    <Route
      {...rest}
      render={setComponent}
    />
  );
};

export default ProtectedRoute;
