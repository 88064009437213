const labelText = ({ label }) => label ? `${label} ` : ''

export const esConfig = {
  mixed: {
    default: (prm) => `${labelText(prm)} es inválido`,
    required: '¡este campo es requerido!',
    oneOf: (prm) =>
      `${labelText(prm)}debe ser uno de los siguientes valores: ${prm.values}`,
    notOneOf: (prm) =>
      `${labelText(prm)}no debe ser uno de los siguientes valores: ${prm.values}`,
    notType: (prm) => {
      if (prm.type === 'number') {
        return `${labelText(prm)}debe ser un número.`
      }
      if (prm.type === 'string') {
        return `${labelText(prm)}debe ser una cadena de texto.`
      }
      if (prm.type === 'date') {
        return `${labelText(prm)}debe ser una fecha válida.`
      }
      return `${labelText(prm)}tiene un formato inválido.`
    }
  },
  string: {
    length: ({ length }) => `ingrese ${length} caracter(es).`,
    min: ({ min }) => `ingrese min. ${min} caracter(es).`,
    max: ({ max }) => `límite max. ${max} caracter(es).`,
    matches: (prm) => `ingrese ${labelText(prm)}en un formato válido.`,
    email: 'correo inválido.',
    url: (prm) => `${labelText(prm)}debe ser una URL válida.`,
    uuid: (prm) => `${labelText(prm)}debe ser un UUID válido.`,
    trim: (prm) => `${labelText(prm)}no debe tener espacios al inicio o final.`,
  },
  number: {
    min: (prm) => `${labelText(prm)}debe ser mínimo ${prm.min}.`,
    max: (prm) => `${labelText(prm)}debe ser máximo ${prm.max}.`,
    lessThan: (prm) => `${labelText(prm)}debe ser menor que ${prm.less}.`,
    moreThan: (prm) => `${labelText(prm)}debe ser mayor que ${prm.more}.`,
    positive: (prm) => `${labelText(prm)}debe ser número positivo.`,
    negative: (prm) => `${labelText(prm)}debe ser número negativo.`,
    integer: (prm) => `${labelText(prm)}debe ser número entero.`
  },
  date: {
    min: (prm) => `${labelText(prm)}debe ser una fecha porterior a ${prm.min}.`,
    max: (prm) => `${labelText(prm)}debe ser una fecha anterior a ${prm.min}.`
  },
  boolean: {
    isValue: (prm) => `${labelText(prm)}requiere ser seleccionado.`
  },
  array: {
    length: (prm) => `${prm.length} ${labelText(prm)}son requeridos.`,
    min: (prm) => `${labelText(prm)}debe tener al menos ${prm.min} elementos.`,
    max: (prm) => `${labelText(prm)}no debe contener más de ${prm.max} elementos.`
  }
}