import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from 'src/components/Common/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';

const Dashboard = lazy(() => import('./Dashboard'));
const Conjuntos = lazy(() => import('./Conjuntos'));
const Administracion = lazy(() => import('./Administradores'));
const NewUser = lazy(() => import('../usuarios/AddUsersConjunto/AddUsersConjunto'));
const RootEmpresa = ({ match }) => (

  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
        <Route
          exact
          path={`${match.url}/dashboard`}
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          exact
          path={`${match.url}/conjuntos`}
          render={(props) => <Conjuntos {...props} />}
        />
        <Route
          exact
          path={`${match.url}/administracion`}
          render={(props) => <Administracion {...props} />}
        />
         <Route
          path={`${match.url}/conjuntos/newuser/:threadKey`}
          exact
          render={(props) => <NewUser {...props} />}
        />
        
      </Switch>
    </Suspense>
  </DashboardLayout>
);

export default RootEmpresa;