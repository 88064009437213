import { firestore as db } from 'src/Firebase';
import moment from 'moment';
import { complexRef } from 'src/services/complexServices';

export const getAlerta = (observer, conjuntoUid) => db
  .collection('conjuntos')
  .doc(conjuntoUid)
  .collection('panico')
  .where('Estado', '==', 1)
  .orderBy('Fecha', 'desc')
  .limit(1)
  .onSnapshot(observer);

export const getAlertsByState = (conjuntoUid, state, observer) => db
  .collection('conjuntos')
  .doc(conjuntoUid)
  .collection('panico')
  .where('Estado', '==', state)
  .orderBy('Fecha', 'desc')
  .onSnapshot(observer);

export const getAllAlerts = (conjuntoUid) => db
  .collection('conjuntos')
  .doc(conjuntoUid)
  .collection('panico')
  .get();

export const updateestado = (idconjuntos, form, observation) => {
  const batch = db.batch();
  // eslint-disable-next-line no-restricted-syntax
  for (const e of form) {
    const { id } = JSON.parse(e);
    const docRef = db
      .collection('conjuntos')
      .doc(idconjuntos)
      .collection('panico')
      .doc(id);
    batch.update(docRef, {
      Estado: 0,
      Observacion: observation,
    });
  }
  return batch.commit();
};

export const deleteAlerta = (idconjuntos, form) => {
  const batch = db.batch();
  // eslint-disable-next-line no-restricted-syntax
  for (const e of form) {
    const { id } = JSON.parse(e);
    const docRef = db
      .collection('conjuntos')
      .doc(idconjuntos)
      .collection('panico')
      .doc(id);
    batch.delete(docRef);
  }
  return batch.commit();
};
//

/* Observer para notificar las entradas de visitas por qr */
export const getVisitaAnticipadaQrByConjunto = (observer, conjuntoID) => db
  .collection('conjuntos').doc(conjuntoID)
  .collection('entradasQr')
  .where('TiempoEntrada', '>=', moment().startOf('day').toDate())
  .where('TiempoEntrada', '<=', moment().endOf('day').toDate())
  .limit(1)
  .onSnapshot(observer);

/* Elimina las entradas de visitas por qr */
export const deleteVisitaAnticipadaQrByConjunto = (conjuntoID, id) => db
  .collection('conjuntos').doc(conjuntoID)
  .collection('entradasQr').doc(id)
  .delete();

/* Procesos para observar entradas por control de acceso */
export const getEntradaCA = (observer, conjuntoID) => db
  .collection('conjuntos').doc(conjuntoID)
  .collection('registrosCA')
  .orderBy('HoraEntrada', 'desc')
  .limit(1)
  .onSnapshot(observer);

export const deleteEntradaCA = (idDoc) => complexRef().collection('registrosCA').doc(idDoc).delete()