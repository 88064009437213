import { configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"
import authMigration from "../migrations/authMigration"
import complexMigration from "../migrations/complexMigration"
import authReducer from "../slices/authSlice"
import complexReducer from "../slices/complexSlice"
import themeReducer from '../slices/themeSlice'

const userPersistConfig = {
  key: "user",
  storage,
  version: 0,
  migrate: authMigration,
}

const complexPersistConfig = {
  key: "complex",
  storage,
  version: 0,
  migrate: complexMigration,
}

const themePersistConfig = {
  key: "theme",
  storage,
  version: 0,
}

const userPersistedReducer = persistReducer(userPersistConfig, authReducer)
const complexPersistedReducer = persistReducer(
  complexPersistConfig,
  complexReducer
)
const themePersistedReducer = persistReducer(themePersistConfig, themeReducer)

export const store = configureStore({
  reducer: {
    auth: userPersistedReducer,
    complex: complexPersistedReducer,
    theme: themePersistedReducer,
  },
  middleware: [thunk],
})

export const persistor = persistStore(store)
