/* eslint-disable react/no-array-index-key */
import {
  useEffect, useRef, useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box, Button, IconButton, List, ListItem, ListItemIcon,
  ListItemText, Collapse, Popover, Tooltip, Typography,
  makeStyles, Icon,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import BuildIcon from '@material-ui/icons/Build';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Event } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import moment from 'moment/moment';
import useUsers from 'src/hooks/useUsers';
import {
  Bell as BellIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import useUsersByUnits from 'src/hooks/useUsersByUnits';
import { sendNotification } from 'src/config/sendNotification';
import * as FirestoreService from './services/firestore';
import AprobarAllReservas from './AprobarAllReservas';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar()
  const [transf, setTransf] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [reparaciones, setReparaciones] = useState([]);
  const [reservas, setReservas] = useState([]);
  const [areas, setAreas] = useState([]);
  const { usuarios } = useUsers();
  const { usersByUnit } = useUsersByUnits()
  const ref = useRef(null);
  const conjunto = useSelector((state) => state.complex.complex);
  const [isOpen, setOpen] = useState(false);
  const [total, setTotal] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    Promise.all([
      FirestoreService.getTransfPendientes(conjunto.id),
      FirestoreService.getCuentasPagar(conjunto.id),
      FirestoreService.getReportes(conjunto.id),
      FirestoreService.getReservas(conjunto.id),
    ]).then((res) => {
      setTransf(res[0])
      setPagos(res[1])
      setReparaciones(res[2])
      setReservas(res[3])
      const count = res.reduce((sum, cur) => sum + cur.length, 0)
      setTotal(count)
      FirestoreService.getAreasComunales(conjunto.id)
        .then((querySnapshot) => {
          const a = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAreas(a);
        })    
    });
  }, [conjunto.id]);

  const findUserName = (userId) => {
    const user = usuarios.find(us => us.id === userId);
    return user ? `${user.Nombre} ${user.Apellido}` : 'Desconocido';
  };

  const findArea = (areaId) => {
    const area = areas.find(a => a.id === areaId);
    return area ? `${area.Nombre}` : 'Desconocido';
  };

  const handleAprobar = async (areaId,reserva) => {
    await FirestoreService.updateresv(
      conjunto.id,
      areaId,
      reserva.id,
      { Aprobada: true }
    ).then(() => {
      enqueueSnackbar('Reserva aprobada, se envió la notificación al residente.', {
        variant: 'success',
      });
      setReservas((prevReservas) =>
        prevReservas.filter((r) => r.id !== reserva.id)
      );
    })
    .catch(() => {
      enqueueSnackbar('Hubo un error al aprobar la reserva.', {
        variant: 'error',
      });
    });
    const { tokens } = usersByUnit.find( doc => doc.idUser === reserva.Usuario)?.tokens || []
    sendNotification(
      `Reserva de ${findArea(areaId)}`,
      `¡Su reserva con fecha ${moment(reserva.Inicio.toDate()).format(
        "DD-MM-YYYY"
      )} ha sido aprobada!`,
      tokens,
    )
  }

  return (
    <>
      <Tooltip title="Notificaciones">
        <IconButton ref={ref} onClick={handleOpen}>
          <Badge badgeContent={total} color="primary" overlap="rectangular">
            <BellIcon style={{ color: conjunto.Kondominios ? 'white' : 'initial' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h6" color="textPrimary">
            Notificaciones
          </Typography>
        </Box>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <List disablePadding style={{ maxHeight: '400px', width: '100%', maxWidth: '400px' }}>
            <SectionList title="Transferencias pendientes" icon={CreditCardIcon}>
              {transf.length > 0 ? (
                <List component="div" disablePadding>
                  {transf.map((item, ind) => (
                    <ListItem
                      component={RouterLink}
                      divider
                      key={ind}
                      to="/administrador/transferenciasporaprobar"
                    >
                      <ListItemText
                        primary={`Ha recibido una transferencia con el valor de: ${item.Valor}$ cubriendo ${item.Deudas} deuda(s)`}
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary',
                        }}
                        secondary={`razón de pago: ${item.Detalle}`}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box p={2} display="flex" justifyContent="center">
                  <Typography color="textPrimary">
                    No tienes transferencias pendientes
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="center">
                <Button component={RouterLink} size="small" to="/administrador/transferenciasporaprobar">
                  ir a transferencias
                </Button>
              </Box>
            </SectionList>
            <SectionList title="Cuentas por Pagar" icon={MoneyIcon}>
              {pagos.length > 0 ? (
                <List component="div" disablePadding>
                  {pagos.map((item, ind) => (
                    <ListItem
                      component={RouterLink}
                      divider
                      key={ind}
                      to="/administrador/proveedores/cuentasporpagar"
                    >
                      <ListItemText
                        primary={`El pedido de ${item.Articulo} está vencido o cercano a vencerse`}
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary',
                        }}
                        secondary={`Proveedor: ${item.Proveedor} - Valor: ${item.Costo}$`}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box p={2} display="flex" justifyContent="center">
                  <Typography color="textPrimary">
                    No tienes cuentas por pagar
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="center">
                <Button component={RouterLink} size="small" to="/administrador/proveedores/cuentasporpagar">
                  ir a cuentas por pagar
                </Button>
              </Box>
            </SectionList>
            <SectionList title="Reportes de daños" icon={BuildIcon}>
              {reparaciones.length > 0 ? (
                <List component="div" disablePadding>
                  {reparaciones.map((item, ind) => (
                    <ListItem
                      component={RouterLink}
                      divider
                      key={ind}
                      to="/administrador/informeProblemas"
                    >
                      <ListItemText
                        primary={`${item.Usuario} ha reportado un daño`}
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary',
                        }}
                        secondary={`Daño: ${item.Observaciones}`}
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box p={2} display="flex" justifyContent="center">
                  <Typography color="textPrimary">
                    No tienes daños reportados
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="center">
                <Button component={RouterLink} size="small" to="/administrador/informeProblemas">
                  ir a reportes de daños
                </Button>
              </Box>
            </SectionList>
            <SectionList title="Reservas de Areas Comunales" icon={Event}>
              {reservas.length > 0 ? (
                <List component="div" disablePadding>
                  {reservas.map((item, ind) => (
                    <ListItem divider key={ind}>
                      <ListItemText
                        primary={
                          <>
                            <b>{findUserName(item.Usuario)}</b> ha reservado{" "}
                            <b>{findArea(item.areaId)}</b>
                          </>
                        }
                        primaryTypographyProps={{
                          variant: "subtitle2",
                          color: "textPrimary",
                        }}
                        secondary={`para ${moment(item.Inicio.toDate()).format(
                          "DD-MM-YYYY"
                        )} de ${moment(item.Inicio.toDate()).format(
                          "HH:mm A"
                        )} a ${moment(item.Fin.toDate()).format("HH:mm A")}`}
                      />
                      <Button
                        variant="outlined"
                        onClick={() => handleAprobar(item.areaId, item)}
                      >
                        Aprobar
                      </Button>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box p={2} display="flex" justifyContent="center">
                  <Typography color="textPrimary">No hay reservas.</Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="center">
                {reservas.length > 0 && (
                  <AprobarAllReservas
                    conjunto={conjunto}
                    reservas={reservas}
                    setReservas={setReservas}
                    usersByUnit={usersByUnit}
                  />
                )}
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  component={RouterLink}
                  size="small"
                  to="/administrador/reservasxaprobar"
                >
                  ir a administrar reservas
                </Button>
              </Box>
            </SectionList>
          </List>
        </PerfectScrollbar>
      </Popover>
    </>
  );
};

const SectionList = ({ title, icon, children }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Icon component={icon} />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

export default Notifications;
