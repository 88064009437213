/* eslint-disable no-unused-vars */
import 'react-notifications-component/dist/theme.css'
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import UIfx from 'uifx';
import {
  Button, Dialog, DialogContent, DialogContentText,
  DialogTitle, Grid, makeStyles, Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { getComplex, selectCamaraGarita, selectConjunto, selectGarita } from 'src/redux/slices/complexSlice';
import SplashScreen from 'src/components/Common/SplashScreen';
import axiosInstance from 'src/utils/axios';
import { selectUser } from 'src/redux/slices/authSlice';

import NavBar from './NavBar';
import TopBar from './TopBar';

import * as FirestoreService from '../../views/viewGuardia/alertas/services/firestore';
import tickAudio from '../../views/viewGuardia/alertas/mySounds/police.mp3';

/* sonido para alerta de panico */
const tick = new UIfx(tickAudio, {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100,
});

const useStyles = makeStyles((theme) => ({
  rootAdmin: {
    background: '#fffff',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 40,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const DashboardGuardia = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector(selectUser);
  const conjunto = useSelector(selectConjunto);
  const [loading, setLoading] = useState(true);

  const listenAlert = (idConjunto) => {
    const hasAlert = false
    const unsuscriber = FirestoreService.getAlerta(
      {
        next: (querySnapshot) => {
          const alert = querySnapshot.docs[0] || null;
          setLoading(false);

         
        },
        error: () => {
          enqueueSnackbar('Error al obtener registro de alertas', { variant: 'error' })
        }
      },
      idConjunto,
    );
    return { unsuscriber, hasAlert }
  };
  /* Para notificaciones de alertas de pánico */
  useEffect(() => {
    let unsuscribers = []
    let isMounted = true
    if (isMounted) {
      if (user.complexs?.length > 0) {
        unsuscribers = user.complexs.map(complex => listenAlert(complex.uid))
      } else {
        const suscriber = listenAlert(user.ConjuntoUidResidencia)
        unsuscribers.push(suscriber)
      }
    }

    return () => {
      unsuscribers.forEach(res => { res?.unsuscriber?.() })
      isMounted = false
      setLoading(true)
    };
  }, [conjunto.id]);

  /** Para las notificaciones al guardia */
  useEffect(() => {
    let id = '';
    let tipo = '';
    const unsub = FirestoreService.getVisitaAnticipadaQrByConjunto({
      next: (query) => {
        query.docChanges().forEach((change) => {
          tipo = change.type;
          const data = change.doc.data();
          id = change.doc.id;
          const { Tipo, Titulo, Mensaje } = data;
          if (tipo === 'added') {
            Swal.fire({
              icon: Tipo,
              title: Titulo,
              text: Mensaje,
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                FirestoreService
                  .deleteVisitaAnticipadaQrByConjunto(conjunto.id, id)
                  .then(() => {
                    if (Tipo === 'success') {
                      enqueueSnackbar('Entrada Registrada', { variant: 'success' });
                    }
                  })
                  .catch(() => { });
              }
            });
          }
        });
      }
    }, conjunto.id);

    return unsub
  }, []);



  if (loading) {
    return <SplashScreen />;
  }
  return (
    <div className={classes.rootAdmin}>
      <TopBar />

      <NavBar />

      <AlertGarita />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const AlertGarita = () => {
  const currentGarita = useSelector(selectCamaraGarita)
  const conjunto = useSelector((state) => state.complex.complex)
  const dispatch = useDispatch()
  const [showAler, setShowAler] = useState(false)
  const [garitas, setGaritas] = useState([])

  const handleOpen = () => setShowAler(true)

  const handleConfirmGarita = (numGarita) => {
    dispatch(selectGarita(numGarita))
    setShowAler(false)
  }

  useEffect(() => {
    if (conjunto.id) {
      axiosInstance.get('api/camaras_bitacora/', { params: { id_conjunto: conjunto.id } })
        .then(({ data }) => {
          if (data.count > 1) {
            const groupGaritas = data.results.map((garita) => garita.camara_garita)
            setGaritas(groupGaritas)
            handleOpen()
          } else {
            handleConfirmGarita(null)
          }
        })
        .catch(() => { })
    }
  }, [conjunto.id])

  if (garitas <= 0 || Boolean(currentGarita)) {
    return null
  }
  return (
    <Dialog
      open={showAler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown
      scroll="paper"
    >
      <DialogTitle>CONJUNTO CON MULTIPLES GARITAS</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Para continuar debe seleccionar la garita en la que se encuentra:
        </DialogContentText>
        <Grid container spacing={2} justifyContent="space-between">
          {garitas.map((garita) => (
            <Grid item xs={12} md={6} key={garita}>
              <Typography variant="h4">
                Garita
                {' '}
                {garita}
              </Typography>
              <br />
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleConfirmGarita(garita)}
              >
                Seleccionar
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

DashboardGuardia.propTypes = {
  children: PropTypes.node,
};

DashboardGuardia.defaultProps = {
  children: '',
};

export default DashboardGuardia;
