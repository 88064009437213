import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import app from 'src/Firebase';
import moment from 'moment';
import { complexRef } from 'src/services/complexServices';

const db = app.firestore();

export const getCuentasPagar = async (idConjunto) => {
  let data = [];
  const fecha = moment().add(1, 'month').endOf('day').toDate()
  const res = await db
    .collection('conjuntos')
    .doc(idConjunto)
    .collection('cuentasPagar')
    .where('Plazo', '<=', fecha)
    .orderBy('Plazo', 'desc')
    .limit(5)
    .get();

  data = res.docs.map((doc) => ({
    Proveedor: doc.data().ProveedorNombre,
    Articulo: doc.data().Detalle,
    Costo: doc.data().Costo,
  }));

  return data;
};

export const getReportes = async (idConjunto) => {
  let data = [];
  const res = await db.collection('conjuntos').doc(idConjunto).collection('issuesReport')
    .where('Estado', '==', 1)
    .limit(5)
    .get();

  data = res.docs.map((snap) => ({
    Usuario: snap.data().DetalleUsuario,
    Observaciones: snap.data().Observaciones,
  }));

  return data;
};
export const getTransfPendientes = async (idConjunto) => {
  const data = [];
  const cuentasRef = db.collection('conjuntos').doc(idConjunto).collection('cuentas');

  const cuentas = await cuentasRef.where('TipoMetodo', '==', 'Cuenta Bancaria').get()
  if (cuentas.empty) return data;
  cuentas.docs.every((cuenta) => {
    if (data.length === 5) return false;
    cuentasRef.doc(cuenta.id)
      .collection('transferencias')
      .where('Estado', '==', 'Pendiente')
      .limit(5)
      .get()
      .then((transf) => {
        if (transf.empty) return true;
        transf.forEach((doc) => {
          const Deudas = [...doc.data().Deuda].length;
          data.push({
            Valor: doc.data().Valor,
            Detalle: doc.data().Detalle,
            Deudas,
          });
        });
      });
    return true;
  });
  return data;
};

export const getAreasComunales = async(idConjunto) => {
  const areasSnapshot = await db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("areascomunales")
    .get();
    return areasSnapshot
};

export const getReservas = async (idConjunto) => {
  const areasSnapshot = await db
    .collection("conjuntos")
    .doc(idConjunto)
    .collection("areascomunales")
    .get();

  const reservasPromises = areasSnapshot.docs.map(async (areaDoc) => {
    const areaId = areaDoc.id;
    const reservasSnapshot = await db
      .collection("conjuntos")
      .doc(idConjunto)
      .collection("areascomunales")
      .doc(areaId)
      .collection("reservaciones")
      .where("Aprobada", "==", false)
      .get();

    return reservasSnapshot.docs.map((reservaDoc) => ({
      id: reservaDoc.id,
      areaId,
      ...reservaDoc.data(),
    }));
  });

  const reservasArray = await Promise.all(reservasPromises);
  const reservas = reservasArray.flat();

  return reservas;
};

export const updateresv = (conjuntoID, idArea, idDoc, reserva) => complexRef(conjuntoID)
  .collection('areascomunales')
  .doc(idArea)
  .collection('reservaciones')
  .doc(idDoc)
  .update(reserva);