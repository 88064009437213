/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Button,
  Tooltip,
} from "@material-ui/core";
import { LocalParkingOutlined } from "@material-ui/icons";
import Dashboard from "@material-ui/icons/Dashboard";
import Home from "@material-ui/icons/AddAlert";
import ListAlt from "@material-ui/icons/ListAlt";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import MenuIcon from "@material-ui/icons/Menu";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LogoDown from "src/components/Common/LogoDown";
import bgimage from "src/assets/img/nav_nachos@2x.png";
import { selectIsParkey } from "src/redux/slices/complexSlice";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import NavItem from "./NavItem";

const sectionsAdmin = [
  {
    items: [
      {
        title: "Registro de Entradas",
        icon: Dashboard,
        href: "/guardia/dashboard",
      },
      {
        title: "Alerta",
        icon: Home,
        href: "/guardia/alerta",
      },
      {
        title: "Bitácora Digital",
        icon: ListAlt,
        href: "/guardia/bitacoraDigital",
      },

      {
        title: "Novedades",
        icon: MenuBookIcon,
        href: "/guardia/eventos",
      },
      {
        title: "Bitácoras Rechazadas",
        icon: LowPriorityIcon,
        href: "/guardia/bitacoraRechazada",
      },
      {
        title: "Visitas anticipadas",
        icon: EmojiPeopleIcon,
        href: "/guardia/visitasAnticipadas",
      },
      {
        title: "Listas",
        icon: EmojiPeopleIcon,
        href: "/guardia/listas",
      },

      {
        title: "Historial",
        icon: LibraryBooksIcon,
        items: [
          {
            title: "Ingresos",
            href: "/guardia/ingresos",
          },

          {
            title: "Rondas",
            href: "/guardia/rondas",
          },
          {
            title: "Reservas",
            href: "/guardia/reservas",
          },
        ],
      },
      {
        title: "Familia/Inquilinos",
        icon: SupervisorAccountIcon,
        href: "/guardia/familia",
      },
    ],
  },
];
const sectionsParkey = [
  {
    items: [
      {
        title: "Parkey",
        icon: LocalParkingOutlined,
        href: "/parkey/parqueadero",
      },
      {
        title: "Bitácora Digital",
        icon: ListAlt,
        href: "/parkey/bitacoraParkey",
      },
      {
        title: "Pagos",
        icon: MonetizationOnIcon,
        href: "/parkey/pagosParkey",
      },
    ],
  },
];

const renderNavItems = ({ items, pathname, depth = 0 }) => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({ depth: depth + 1, pathname, items: item.items })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  mobileDrawer: {
    width: 256,
    height: "100%",
  },
  desktopDrawer: {
    width: 256,
    height: "100%",
  },
  avatar: {
    cursor: "pointer",
    width: 100,
    height: 100,
  },
  dividerColor: {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const userSection = sectionsAdmin;
  const userSectionParkey = sectionsParkey;
  const isParkey = useSelector(selectIsParkey);
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.root}
      style={{ backgroundImage: `url(${bgimage})` }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <LogoDown />
        </Box>
        <Divider className={classes.dividerColor} variant="middle" />
        {isParkey ? (
          <Box p={2}>
            {userSectionParkey.map((section, ind) => (
              <List
                key={ind}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
                {/* <Divider className={classes.dividerColor} /> */}
              </List>
            ))}
          </Box>
        ) : (
          <Box p={2}>
            {userSection.map((section, ind) => (
              <List
                key={ind}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
                {/* <Divider className={classes.dividerColor} /> */}
              </List>
            ))}
          </Box>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden only="lg">
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          variant="temporary"
          open={open}
          onClose={() => setOpen(false)}
        >
          {content}
        </Drawer>
        <Tooltip title="Abrir menú lateral">
          <Button
            style={{
              position: "absolute",
              left: 10,
              top: 10,
              zIndex: 1100,
              backgroundColor: "#051e34",
              color: "#fff",
            }}
            onClick={() => setOpen(true)}
            variant="contained"
          >
            <MenuIcon />
          </Button>
        </Tooltip>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
