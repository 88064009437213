import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import 'src/assets/css/scrollbar.css'

const useStyles = makeStyles((theme) => ({
  rootAdmin: {
    background: '#fffff',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 40,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256,
    },
    marginTop: theme.spacing(5),
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
  },
}));
const DashboardLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.rootAdmin}>
      <TopBar />

      <NavBar />

      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

DashboardLayout.defaultProps = {
  children: '',
};

export default DashboardLayout;
