import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Tooltip,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'

import { selectRol } from 'src/redux/slices/authSlice';
import LogoDown from 'src/components/Common/LogoDown';
import bgImage from 'src/assets/img/nav_nachos@2x.png';
import NavItem from './NavItem';

import navbars from './navbars';

const renderNavItems = ({ items, pathname, section, depth = 0 }) => (
  <List
    disablePadding
    subheader={(
      <ListSubheader>{section}</ListSubheader>
    )}
  >
    {items.reduce(
      (acc, item) => reduceChildRoutes({
        acc, item, pathname, depth,
      }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({
  acc, pathname, item, depth,
}) => {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          section: item.section,
          items: item.items
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />,
    );
  }

  return acc;
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  mobileDrawer: {
    width: 256,
  },
  dividerColor: {
    backgroundColor: theme.palette.primary.contrastText,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const rol = useSelector(selectRol)
  const [open, setOpen] = useState(false);
  const breakpoint = useMediaQuery(theme => theme.breakpoints.up('md'))

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        variant={breakpoint ? 'persistent' : "temporary"}
        open={(breakpoint || open)}
        onClose={() => setOpen(false)}
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          className={classes.root}
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box p={2}>
              <LogoDown />
            </Box>
            <Divider className={classes.dividerColor} variant="middle" />
            <Box p={2}>
              {renderNavItems({
                items: navbars[rol] || [],
                pathname: location.pathname,
              })}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Drawer>
      <Hidden mdUp>
        <Tooltip title="Abrir menú lateral">
          <Button
            style={{
              position: 'absolute', left: 10, top: 10, zIndex: 1100, backgroundColor: '#051e34', color: '#fff',
            }}
            onClick={() => setOpen(true)}
            variant="contained"
          >
            <MenuIcon />
          </Button>
        </Tooltip>
      </Hidden>
    </>
  );
};

export default NavBar;
