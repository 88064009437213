import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getUserByConjunto } from 'src/views/usuarios/services/firestore';
import { getUnidadesHab } from 'src/services/admin/resident';

const useUsers = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [unitsHab, setUnitsHab] = useState([])
  const [usersByUnit, setUsersByUnit] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const conjunto = useSelector((state) => state.complex.complex)

  useEffect(() => {
    if (conjunto?.id) {
      const unsub = getUserByConjunto({
        next: (query) => {
          const data = query.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          setUsuarios(data)
        }
      }, conjunto.id)
      return () => {
        setUsuarios([])
        unsub?.()
      }
    }
  }, [conjunto.id])

  useEffect(() => {
    if (conjunto?.id) {
      const unSub = getUnidadesHab(conjunto.id, {
        next: (query) => {
          const data = query.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          setUnitsHab(data)
        }
      })

      return () => {
        setUnitsHab([])
        unSub?.()
      }
    }
  }, [conjunto.id])

  useEffect(() => {
    if (usuarios.length > 0 && unitsHab.length > 0) {
      setIsLoading(true)
      const objUsers = {}
      usuarios.forEach((elem) => {
        objUsers[elem.id] = { ...elem }
      })
      const listOfUsers = []
      unitsHab.forEach((unit) => {
        if (unit.IdPropietario) {
          const data = objUsers[unit.IdPropietario]
          if (data) {
            listOfUsers.push({
              ...data,
              id: `${unit.id}/${data.id}`,
              idUser: data.id,
              idUnit: unit.id,
              Casa: unit.Numero,
              Rentada: unit.Rentada,
              IdInquilino: unit.IdInquilino || "",
              fullname: `${data.Nombre} ${data.Apellido}`,
              label: `${unit.Numero} -> ${data?.Nombre} ${data?.Apellido}`
            })
          }
        }
        if (unit.IdInquilino) {
          const data = objUsers[unit.IdInquilino]
          if (data) {
            listOfUsers.push({
              ...data,
              EsPropietario: false,
              id: `${unit.id}/${data.id}`,
              idUser: data.id,
              idUnit: unit.id,
              Casa: unit.Numero,
              fullname: `${data.Nombre} ${data.Apellido}`,
              label: `${unit.Numero} -> ${data?.Nombre} ${data?.Apellido}`
            })
          }
        }
      })
      listOfUsers.sort((a, b) => a.Casa?.toString().localeCompare(b.Casa?.toString(), 'en', { numeric: true }));
      setUsersByUnit(listOfUsers)
      setIsLoading(false)
    }
  }, [usuarios, unitsHab])

  return { isLoading, usersByUnit, usuarios, unitsHab }
}

export default useUsers
