import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LoadingScreen from "src/components/Common/LoadingScreen";
import DashboardLayout from "src/layouts/DashboardLayout";

const Dashboard = lazy(() => import("./dashboard"));
const Conjuntos = lazy(() => import("../conjuntos/conjuntos"));
const Administradores = lazy(() => import("./administradores/administrador"));
const Parametros = lazy(() => import("./Parametros/Parametros"));
const NewUser = lazy(() =>
  import("../usuarios/AddUsersConjunto/AddUsersConjunto")
);
const CargarDatos = lazy(() => import("./datosIniciales/index"));
const UnidadesH = lazy(() => import("../conjuntos/UnidadHabitacional"));
const PortalPagos = lazy(() => import("../conjuntos/PortalPagos"));
const Splash = lazy(() => import("./Splash/Splash"));
const Company = lazy(() => import("./Company/index"));
const UserTGLabs = lazy(() => import("./TGLabsUsers/index"));
const Sugerencias = lazy(() => import("./Sugerencias"));
const InfoForHelp = lazy(() => import("./InfoForHelp"));
const AllPanics = lazy(() => import("./AllPanics"));
const FAQ = lazy(() => import("./FAQ"));

const SuperAdmin = ({ match }) => (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
        <Route
          path={`${match.url}/dashboard`}
          exact
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          path={`${match.url}/conjuntos`}
          exact
          render={(props) => <Conjuntos {...props} />}
        />

        <Route
          path={`${match.url}/conjuntos/:threadKey`}
          exact
          render={(props) => <Conjuntos {...props} />}
        />
        <Route
          path={`${match.url}/administradores`}
          exact
          render={(props) => <Administradores {...props} />}
        />
        <Route
          path={`${match.url}/conjuntos/newuser/:threadKey`}
          exact
          render={(props) => <NewUser {...props} />}
        />
        {/* ------------- */}
        <Route
          path={`${match.url}/conjuntos/cargardatos/:threadKey`}
          exact
          render={(props) => <CargarDatos {...props} />}
        />

        <Route
          path={`${match.url}/conjuntos/unidadesH/:threadKey`}
          exact
          render={(props) => <UnidadesH {...props} />}
        />
        <Route
          path={`${match.url}/conjuntos/portalPagos/:threadKey`}
          exact
          render={(props) => <PortalPagos {...props} />}
        />

        <Route
          path={`${match.url}/Company`}
          exact
          render={(props) => <Company {...props} />}
        />
        <Route
          path={`${match.url}/usertglabs`}
          exact
          render={(props) => <UserTGLabs {...props} />}
        />
        <Route
          path={`${match.url}/splash`}
          exact
          render={(props) => <Splash {...props} />}
        />
        <Route
          path={`${match.url}/parametros`}
          exact
          render={(props) => <Parametros {...props} />}
        />
        <Route
          path={`${match.url}/sugerencias`}
          exact
          render={(props) => <Sugerencias {...props} />}
        />
        <Route
          path={`${match.url}/infoApp`}
          exact
          render={(props) => <InfoForHelp {...props} />}
        />
        <Route
          path={`${match.url}/FAQ`}
          exact
          render={(props) => <FAQ {...props} />}
        />
        <Route
          path={`${match.url}/allPanics`}
          exact
          render={(props) => <AllPanics {...props} />}
        />
      </Switch>
    </Suspense>
  </DashboardLayout>
);

export default SuperAdmin;
